.StreamIdEditor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    .content-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        max-width: 90vw;
        max-height: 90vh;
        background-color: black;
        border-radius: 10px;
        padding: 20px;
        color: white;
    }
}
