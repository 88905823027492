.Icon {
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    img {
        width: 65%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.Icon_red {
        background-color: #990000;
    }

    &.Icon_loading {
        img {
            animation-name: Icon_spin_animation;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes Icon_spin_animation {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(135deg);
    }
}
