.dualmode-indicator {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 40px;
    height: 40px;
    // border: 1px solid white;
    background-color: black;
    // opacity: 0.75;
    transition-duration: 0.3s;
    transition-delay: 0.15s;
    z-index: 450;
    border-radius: 100%;

    &:after {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        content: " ";
        display: block;
        background: white;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        border-radius: 100%;
        transition: 0.5s;
    }

    &.side-r {
        &:after {
            display: block;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 50%,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 1) 100%
            );
        }
    }
}
