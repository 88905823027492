.WebRTCStream {
    // Set in react component dynamically
    width: 200px;
    height: 200px;

    // background-color: #c cc;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 20px;
    // border: 2px solid rgba(255, 255, 0, 0);
    border-radius: 10px;
    transition: border-color 0.5s;
    box-sizing: border-box;
    cursor: pointer;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #333;
    }
    .controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .loading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(75%);
        }
        .sizes {
            position: absolute;
            bottom: 10px;
            left: 10px;

            // display: none;
        }
        &:hover {
            .sizes {
                display: block;
            }
        }
        .size {
            background-color: black;
            color: white;
            padding: 5px;
            font-size: 12px;
            display: inline-block;
            height: 15px;
            border-radius: 8px;
            font-family: 'Courier New', Courier, monospace;
            margin-right: 10px;
            cursor: pointer;
            transition: opacity 0.33s;
            &:hover {
                opacity: 0.5;
            }
        }
        .name {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(255, 255, 255, 0.5);
            padding: 10px;
            font-size: 14px;
            letter-spacing: 2px;
            white-space: nowrap;
        }

        .preview {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
        }
    }

    .loading-copy {
        position: absolute;
        bottom: 50px;
        left: 10%;
        font-size: 10px;
        font-weight: bold;
        color: white;
        width: 80%;
        text-align: center;
        display: none;
    }

    &.focussed {
        display: none;
    }
}

.WebRTCStream.loading {
    border-style: solid;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 1);

    .loading-copy {
        display: block;
    }
}

.grid .WebRTCStream {
    border: 0;
    border-radius: 0px;
    margin-left: auto;
    margin-right: auto;

    .webrtc-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 10px;
        overflow: hidden;
    }

    &.loading {
        .webrtc-wrapper {
            border-style: solid;
            border-width: 2px;
            border-color: rgba(255, 255, 255, 1);

            width: 90%;
            height: 90%;
        }
        .loading-copy {
            display: block;
        }
    }
}
