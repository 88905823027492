.VideoChat {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    width: 200px;
    transition: right 0.5s;

    .VideoChat_Main {
        display: flex;
        flex-direction: column;
        height: 100%;

        .menu {
            background-color: #333;
            padding: 8px 10px;
            border-radius: 10px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            transition-duration: 0.5s;
            transition-property: height, padding;
        }

        .spacer {
            height: 10px;
        }
        .participants {
            // background-color: yellow;
            flex: 1;
            width: 100%;
            overflow: auto;
        }
    }

    &.chatVisible {
        right: 380px;
    }
}
