.Conference {
    display: inline-block;
}
.feeds {
    display: inline-block;
}
.local-video {
    display: none;
}
.ShootHub {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    overflow: hidden;

    .logo {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 160px;
        z-index: 50;
        transition: top 0.5s;
        img {
            width: 100%;
        }
    }
    .main-content-wrapper {
        transition-property: margin;
        transition-duration: 0.5s;
        height: 100%;
    }
    .main-content {
        height: 100%;
        width: 100%;
        position: relative;
    }

    .loading-indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);

        font-size: 14px;
        color: white;
        text-align: center;
    }
    &.grid {
        .loading-indicator {
            display: none;
        }
    }

    .main-video {
        z-index: 10;
        position: relative;
        height: 100%;
        width: 100%;
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &.dualfeed {
        .main-video {
            float: left;
            width: 50%;
        }
    }
    &.grid {
        .main-video {
            visibility: hidden;
        }
    }
    .video-thumbnails {
        z-index: 20;
        position: fixed;
        bottom: 20px;
        left: 20px;

        transition: bottom 0.5s;
        &.grid {
            top: 20px;
            right: 20px;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &.hide-ui {
        cursor: none;
        * {
            cursor: none;
        }

        .logo {
            top: -200px;
        }
        .video-thumbnails {
            bottom: -250px;
            &.grid {
                bottom: 20px;
            }
        }
        .Options,
        .Fullscreen,
        .MuteToggle,
        .dualmode-indicator,
        .ChatToggle,
        .VideoChatToggle,
        .MenuToggle {
            opacity: 0;
        }
        .VideoChat {
            .VideoChat_Main {
                .menu {
                    height: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    &.chat-visible {
        .main-content-wrapper {
            margin-right: 380px;
        }
    }

    &.videochat-visible {
        .main-content-wrapper {
            margin-right: 200px + 20px + 10px;
        }
    }
    &.chat-visible.videochat-visible {
        .main-content-wrapper {
            margin-right: 370px + 200px + 20px;
        }
    }
}

// Hotfix, remove name for grid
.grid .controls .name {
  display: none;
}
