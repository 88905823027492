.PasswordPrompt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    .content-container {
        max-width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid black;
        width: 500px;
        box-sizing: border-box;
        padding: 50px;
        .info {
            font-size: 13px;
            margin-bottom: 1em;
            text-align: center;
        }
        .form__button {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .error {
            font-size: 14px;
            font-weight: bold;
            color: #aa0000;
        }
    }

    .img-container {
        width: 100%;
        img {
            width: 100%;
            margin-bottom: 50px;
        }
    }

    .contact-info {
        a {
            display: inline-block;
            margin-bottom: 20px;
            text-decoration: none;
            color: white;
        }
        .shoothub {
            height: 20px;
        }
        .wa {
            height: 20px;
        }
    }
}
