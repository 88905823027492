.VideoStream {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 20px;
    border-radius: 10px;
    box-sizing: border-box;

    &.hidden {
        display: none;
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: #fff;
    }
}
