.Fullscreen {
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 40px;
    width: 40px;
    background-color: black;
    border-radius: 100%;
    cursor: pointer;
    transition: opacity 0.5s;
    z-index: 100;

    img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        left: 10px;
    }
}
