$width: 350px;

#Chat {
    position: fixed;
    top: 20px;
    right: 20px;
    bottom: 20px;
    width: $width;
    background-color: #333;
    z-index: 1000;
    border-radius: 10px;
    overflow: hidden;
    right: 0 - $width - 50px;
    transition-property: right;
    transition-duration: 0.5s;
    &.visible {
        right: 20px;
    }
}

.ChatIntro {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .label {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 1em;
    }
}

.chatMain {
    flex-direction: column;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    > div {
        &.header {
            padding: 10px;
            background-color: #333;
            color: white;
            display: flex;
            border-bottom: 1px solid #fff;
            position: relative;
            > div {
                flex: 1;
            }
            .left {
                font-weight: bold;
                line-height: 30px;
            }
            .right {
                text-align: right;
                .icon {
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    cursor: pointer;
                    transition-duration: 0.5s;
                    margin-left: 15px;
                    &:hover {
                        opacity: 0.75;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
        &.footer {
            padding: 10px;
            background-color: #333;
            color: white;
            border-top: 1px solid #fff;
        }
        &.messages {
            position: relative;
            flex: 1;
            overflow: auto;
            margin: 0px 0;
            background-color: #333;
            // color: white;
        }
        .no-messages-yet {
            margin: auto;
            width: 75%;
            font-weight: bold;
            font-size: 14px;
            text-align: center;
            margin-top: 2em;
            opacity: 0.5;
        }
    }
}

.ChatMessage {
    display: flex;
    padding: 10px 20px 0px 20px;
    &:last-child {
        margin-bottom: 10px;
    }
    .body {
        flex: 1;

        .message {
            font-size: 13px;
            .message-info {
                font-size: 12px;
                display: flex;
                div {
                    flex: 1;
                }
                .name {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: bold;
                    font-size: 11px;
                    padding-bottom: 0.5em;
                }
                .time {
                    width: 75px;
                    text-align: right;
                }
            }
            background-color: #eee;
            padding: 10px;
            border-radius: 5px;

            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }
    .spacer {
        width: 25px;
    }
    &.fromSelf {
        .message {
            background-color: #ddd;
        }
        .spacer {
            order: 1;
        }
        .body {
            order: 2;
        }
        .icon {
            order: 3;
            margin-right: 0;
            margin-left: 10px;
        }
    }
}

.ChatEntry {
    display: flex;

    textarea,
    .auto-spacer {
        padding: 0;
        margin: 0;
        border: 0;
        font: inherit;
        font-size: 13px;
        width: 100%;
        line-height: inherit;
        resize: none;
        padding: 10px;
        box-sizing: border-box;
    }
    .auto-spacer {
        opacity: 0;
    }

    .info {
        font-size: 11px;
        margin-top: 5px;
        span {
            font-family: monospace;
            font-weight: bold;
        }
    }

    .right {
        padding-left: 10px;
        img {
            cursor: pointer;
            transition: 0.5s;
            width: 30px;
            border: 1px solid black;
            border-radius: 100%;
            &:hover {
                opacity: 0.5;
            }
        }
    }
    .left {
        flex: 1;
        .wrapper {
            position: relative;
            textarea {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 1;
                overflow: hidden;
                background-color: #ddd;
                border: 1px solid #fff;
                border-radius: 20px;
            }
        }
    }
}
