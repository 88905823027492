.MenuToggle {
    position: absolute;
    z-index: 500;
    top: 20px;
    right: 20px;
    background-color: black;
    border: 1px solid #aaa;
    border-radius: 20px;
    padding-right: 10px;

    .unreadIndicator {
        position: absolute;
        top: 7px;
        right: 7px;
        width: 10px;
        height: 10px;
        background-color: #990000;
        border-radius: 100%;
    }
    .label {
        color: white;
        font-size: 14px;
        transform: translateY(-15px);
        display: inline-block;
    }
    .Icon {
        display: inline-block;
        background-color: transparent;
    }
}

.Menu-bg {
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
    transition-property: background-color, visibility;
    transition-duration: 0.5s, 0s;
    transition-delay: 0s, 0.5s;
    cursor: pointer;
    &.visible {
        transition-delay: 0s, 0s;
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.Menu {
    $width: 350px;
    $margin: 20px;
    position: absolute;
    z-index: 1000;

    top: $margin;
    width: $width;
    bottom: $margin;
    background-color: #333;
    color: white;
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    right: ($width + $margin) * -1;

    transition-property: right;
    transition-duration: 0.5s;

    .close-menu-icon {
        float: right;
    }

    .title {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 1em;
        margin-top: 2.5em;

        .status {
            float: right;
            width: 10px;
            height: 10px;
            border: 1px solid white;
            border-radius: 100%;
            transition-property: border-color, background-color;
            transition-duration: 0.5s;
            &.on {
                border-color: green;
                background-color: green;
            }
            &.connecting {
                border-color: yellow;
                background-color: yellow;
            }
        }
    }

    .stream-modes {
        font-size: 13px;
        .mode {
            padding: 10px 10px 10px 30px;
            position: relative;
            cursor: pointer;
            &:before {
                width: 8px;
                height: 8px;
                position: absolute;
                left: 10px;
                top: 12px;
                border: 2px solid white;
                border-radius: 100%;
                content: ' ';
                display: block;
            }

            &:hover {
                &:before {
                    background-color: gray;
                }
            }

            &.active {
                &:before {
                    background-color: white;
                }
            }
        }
    }

    &.visible {
        right: $margin;
    }

    .menuRow {
        margin-bottom: 1em;
    }
}
