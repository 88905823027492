.VideoChat__Tile {
    // height: 200px;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    background-color: #333;
    border-radius: 10px;
    overflow: hidden;
    display: none;

    video {
        width: 100%;
        display: block;
        // border-radius: 10px;
    }
    .name {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        background-color: black;
        padding: 3px 3px;
        color: white;
        border-radius: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .muted {
        position: absolute !important;
        bottom: 10px;
        left: 10px;
    }

    &.video_playable {
        display: inline-block;
    }
    &.video_off {
        display: inline-block;
        width: 100%;
        padding-top: 50%;

        background-color: rgba(255, 255, 255, 0.5);
        @supports (
            (-webkit-backdrop-filter: blur(10px)) or
                (backdrop-filter: blur(10px))
        ) {
            background-color: rgba(255, 255, 255, 0.45);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
        }
        .name {
            top: 50%;
            left: 50%;
            width: 90%;
            padding: 5px;
            box-sizing: border-box;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 13px;
            bottom: auto;
        }
    }
    &.video_loading {
        // TODO
        display: none;
    }
}
