.form__button {
    width: 100%;
    border: 1px solid black;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 25px;
    box-sizing: border-box;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    transition-property: background-color, color, border-color;
    transition-duration: 0.3s;

    &.light {
        border-color: white;
        background-color: #ccc;
        color: black;
        &:hover {
            background-color: #aaa;
        }
        &.disabled {
            cursor: not-allowed;
            color: #333;
            background-color: #aaa;
            border-color: #aaa;
        }
    }

    &.dark {
        border-color: black;
        background-color: #333;
        color: #ccc;
        &:hover {
            background-color: #666;
        }
        &.disabled {
            cursor: not-allowed;
            color: #ccc;
            background-color: #666;
            border-color: #777;
        }
    }
}
