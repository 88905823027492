.MenuIntro {
    display: flex;
    flex-direction: column;
    height: 100%;
    .spacer {
        flex: 1;
    }
    .intro-copy {
        margin-top: 3em;
        margin-bottom: 1em;
        line-height: 1.5em;
    }
    .input-wrapper {
        margin-bottom: 1em;
    }
}
