.form__input {
    width: 100%;
    padding: 10px 10px;
    font: inherit;
    font-size: 12px;
    line-height: 12px;
    background-color: #ddd;
    color: black;
    border: 0;
    border-radius: 20px;
    box-sizing: border-box;
    &.dark {
        color: white;
        background-color: #444;
        border: 1px solid #ddd;
    }
}
